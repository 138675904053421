import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ParticlesBg from 'particles-bg'
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';   
import Header from './components/Header';   
import Sponser from './components/Sponser';   
import Speaker from './components/Speaker'; 
import FAQ from "./components/faq";
import CFP from './components/CFP';
import Team from './components/Team';  
import Venue from './components/Venue';  
import Getintouch from './components/Getintouch';  
import Schedule from './components/Schedule';
import { inject } from '@vercel/analytics';
 
inject({ mode:'production'});

const App = () => {
  let config = {
    num: [10, 20],
    rps: 0.4,
    radius: 2,
    life: [1.5, 3],
    v: [2, 3],
    tha: [-40, 40],
    alpha: [0.6, 0],
    scale: [1, 0.1],
    position: "all", // all or center or {x:1,y:1,width:100,height:100}
    color: ["#eba19a"],
    cross: "dead", // cross or bround
    random: 15,  // or null,
    g: 5,    // gravity
    // f: [2, -1], // force
    onParticleUpdate: (ctx, particle) => {
        ctx.beginPath();
        ctx.rect(particle.p.x, particle.p.y, particle.radius * 2, particle.radius * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
        ctx.closePath();
    }
  };
  
  return (
      <>
      <ParticlesBg type="custom" config={config} bg={true} />
      <div className='main'>
        <Router>
          <Header/>
            <Routes>
              <Route  exact path='/' element={<Home/>} />
              <Route  exact path='/about' element={<About/>} />
              <Route  exact path='/team' element={<Team/>} />
              <Route  exact path='/sponsors' element={<Sponser/>} />
              <Route  exact path='/speaker' element={<Speaker/>} />
              <Route  exact path='/getintouch' element={<Getintouch/>} />
              <Route exact path='/faq' element={<FAQ/>} />
              <Route exact path='/cfp' element={<CFP/>} />
              <Route exact path='/venue' element={<Venue/>} />
              <Route exact path='/schedule' element={<Schedule/>} />
            </Routes>
          <Footer/>
        </Router>
      </div>
      </>
  );
}

export default App;