import React from "react";
import '../css/sponsor.css'

const Sponser = () =>{

    const sponsor_details = [
        {
            id: "dgocean",
            name: "Digital Ocean",
            imgURL: require("./images/sponsors/DigitalOcean-Logo.svg")
        },
        {
            id: "bugc",
            name: "Bugcrowd",
            imgURL: require("./images/sponsors/bugcrowd.svg")
        },
        {
            id: "burpb",
            name: "Burp Bounty",
            imgURL: require("./images/sponsors/burpbountylogo.svg")
        },
        {
            id: "gcp",
            name: "Google Cloud Platform",
            imgURL: require("./images/sponsors/Google_Cloud_Platform-Logo.wine.svg")
        },
        {
            id: "htb",
            name: "Hack The Box",
            imgURL: require("./images/sponsors/Hack-The-Box-logo.svg")
        },
        {
            id: "intigriti",
            name: "Intigriti",
            imgURL: require("./images/sponsors/logo_intigriti.svg")
        },
        {
            id: "synack",
            name: "SynAckIv",
            imgURL: require("./images/sponsors/logo_synacktiv_blanc.svg")
        },
        {
            id: "offsec",
            name: "Offensive Security",
            imgURL: require("./images/sponsors/offseclogo.svg")
        },
        {
            id: "penacd",
            name: "Pentester Academy",
            imgURL: require("./images/sponsors/pentesteracadd.svg")
        },
        {
            id: "spyse",
            name: "Spyse",
            imgURL: require("./images/sponsors/spyse.svg")
        },
        {
            id: "thm",
            name: "THM",
            imgURL: require("./images/sponsors/THMlogo.svg")
        },
        {
            id: "xssrat",
            name: "xssrat",
            imgURL: require("./images/sponsors/xssrat.svg")
        }
    ]
    const new_sponsers = [
        {
            id: "bsides2024",
            name: "BSides Goa",
            imgURL: require("./images/new_sponser/bsidesgoa.png")
        },
        {
            id: "securityboat",
            name: "Security Boat",
            imgURL: require("./images/new_sponser/SECURITYBOAT.png")
        },
        {
            id: "thm",
            name: "The Hackers Meetup",
            imgURL: require("./images/new_sponser/thm.png")
        },
        {
            id: "null",
            name: "null mumbai",
            imgURL: require("./images/new_sponser/null1.png")
        },
        {
            id: "ihacknet",
            name: "I hack net",
            imgURL: require("./images/new_sponser/ihacknetlogo.png")
        }
    ]

    const support_partners = [
        {
            id: "csi",
            name: "Cyber Secured India",
            imgURL: require("./images/new_sponser/CSI.png")
        }
    ]

    const s_partners = [
        {
            id: "clouds",
            name: "CloudSek",
            imgURL: require("./images/new_sponser/Cloudsek.png")
        }
    ]

    const l_partners = [
        {
            id: "altered",
            name: "Altered Security",
            imgURL: require("./images/new_sponser/ASVerticalWhiteOutlineLogo.png")
        }
    ]

    const p_partners = [
        {
            id: "offse",
            name: "Offensive Security",
            imgURL: require("./images/new_sponser/OffS.jpg")
        }
    ]


    const c_partners = [
        {
            id: "htb",
            name: "HackTheBox",
            imgURL: require("./images/new_sponser/HTB.png")
        }
    ]

    return(
        <div className="window-main">
            <div className="window-sp">
                <div className="window-content">
                    <div className="sponsorus">
                        <p>Are you interested in sponsoring? Explore our <a style={{color:"#e56c61"}} href="https://www.canva.com/design/DAF-hcuzExA/X-ElUimwxQ95j4xrY9OcSQ/view" target="_blank" rel="noreferrer"> <strong>Sponsorship kit</strong> </a> and get in touch at<a href="mailto:sponsorship@bsidesmumbai.in" rel="noreferrer"> <strong style={{color:"#e56c61"}}>sponsorship@bsidesmumbai.in</strong></a> for further details.</p>
                    </div>
                    <br/>
                    <h2 className="subhead">Past Sponsors & Partners</h2>
                    <div className="first-sponser">
                        <div>
                            <h2 className="subhead">Goodie Bag Sponsor</h2>
                            <div className="sponsor-content">
                                {s_partners.map((member) => {
                                        return(
                                            <div className="sponsor-card">
                                                <img className="sponsor-img" src={(member.imgURL)} alt={member.name} loading="eager"/>
                                            </div>
                                        )
                                })}
                            </div>
                        </div>
                        <div>
                            <h2 className="subhead">Lanyard Sponsor</h2>
                            <div className="sponsor-content">
                                {l_partners.map((member) => {
                                        return(
                                            <div className="sponsor-card">
                                                <img className="sponsor-img" src={(member.imgURL)} alt={member.name} loading="eager"/>
                                            </div>
                                        )
                                })}
                            </div>
                        </div>

                        <div>
                            <h2 className="subhead">In House Competition Sponsor</h2>
                            <div className="sponsor-content">
                                {p_partners.map((member) => {
                                        return(
                                            <div className="sponsor-card">
                                                <img className="sponsor-img" src={(member.imgURL)} alt={member.name} loading="eager"/>
                                            </div>
                                        )
                                })}
                            </div>
                        </div>


                        <div>
                            <h2 className="subhead">CTF Sponsor</h2>
                            <div className="sponsor-content">
                                {c_partners.map((member) => {
                                        return(
                                            <div className="sponsor-card">
                                                <img className="sponsor-img" src={(member.imgURL)} alt={member.name} loading="eager"/>
                                            </div>
                                        )
                                })}
                            </div>
                        </div>


                    </div>
<br/>
                   
                    <h2 className="subhead">Supporting Partners</h2>
                    <div className="sponsor-content">
                        {support_partners.map((member) => {
                                return(
                                    <div className="sponsor-card">
                                        <img className="sponsor-img" src={(member.imgURL)} alt={member.name} loading="eager"/>
                                    </div>
                                )
                        })}
                    </div>
                    <br/>
                    <h2 className="subhead">Community Partners</h2>
                    <div className="sponsor-content">
                        {new_sponsers.map((member) => {
                                return(
                                    <div className="sponsor-card">
                                        <img className="sponsor-img" src={(member.imgURL)} alt={member.name} loading="eager"/>
                                    </div>
                                )
                        })}
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Sponser;