import React, { useState} from "react";
// import { Link } from "react-router-dom";
import '../css/Home.css';
import CountDownTimer from "./CountDownTimer";
import '../css/getin.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Socials from "./Socials";
import { Link } from "react-router-dom";

const Home = () =>{

    const [timeleft , settime] = useState([]);

    const endDate = '05/25/2024 00:00:00';
    const newTime = () =>{
        const now = new  Date(endDate).getTime();
        const countdown = new Date().getTime();
        const distance = now-countdown;
    
        const d = Math.floor(distance / (1000 * 60 * 60 * 24));
        const h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const s = Math.floor((distance % (1000 * 60)) / (1000)); 
        
        settime([d,h,m,s]);
        // return timeleft;
    }
    setInterval(newTime , 1000);

    const customIcon = () =>{
        return(
                <ion-icon style={{width:"2rem", height:"2.5rem"}} name="logo-discord"></ion-icon>

        );
    }
    
    const socials = [
        {
            id: "linkedin",
            name: "L",
            link: "https://in.linkedin.com/company/bsidesmumbai",
            icon: <LinkedInIcon/>
        },
        {
            id: "instagram",
            name: "I",
            link: "https://www.instagram.com/bsidesmumbai/",
            icon: <InstagramIcon/>
        },
        {            
            id: "twitter",
            name: "X",
            link: "https://twitter.com/BSidesMumbai",
            icon: <XIcon style={{width:"2rem"}}/>
        },
        {            
            id: "whatsapp",
            name: "whatsapp",
            link: "https://chat.whatsapp.com/FOLa4NT7zHQ7AoDx4CgwEa",
            icon: <WhatsAppIcon/>
        },
        {            
            id: "discord",
            name: "discord",
            link: "https://discord.gg/2KRGQWBGR3",
            icon: customIcon()
        }
    ]

    return(
        <>
        <div className="mainHome">
            {/* <div className="subtitle">Cyber Security Conference 2024</div> */}
            <h1 className="title">BSides Mumbai</h1>
            <div className="subtitle">Where Security Meets The City Of Dreams…</div>
            <br/><br/>
            <div className="subtitle">2025 Edition Coming Soon...</div>

         
        </div>
        <div className="getin-cont-h">
                {socials.map( (links) => {
                        return <Socials key={links.id} {...links}/>
                })}
        </div>
        </>
    );
}

export default Home;